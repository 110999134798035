/* Base Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0, 0%, 100%;
    --foreground: 222.2, 84%, 4.9%;
    --card: 0, 0%, 100%;
    --card-foreground: 222.2, 84%, 4.9%;
    --popover: 0, 0%, 100%;
    --popover-foreground: 222.2, 84%, 4.9%;
    --primary: 262.1, 83.3%, 57.8%;
    --primary-foreground: 210, 40%, 98%;
    --secondary: 210, 40%, 96.1%;
    --secondary-foreground: 222.2, 47.4%, 11.2%;
    --muted: 210, 40%, 96.1%;
    --muted-foreground: 215.4, 16.3%, 46.9%;
    --accent: 210, 40%, 96.1%;
    --accent-foreground: 222.2, 47.4%, 11.2%;
    --destructive: 0, 84.2%, 60.2%;
    --destructive-foreground: 210, 40%, 98%;
    --border: 214.3, 31.8%, 91.4%;
    --input: 214.3, 31.8%, 91.4%;
    --ring: 262.1, 83.3%, 57.8%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2, 84%, 4.9%;
    --foreground: 210, 40%, 98%;
    --card: 222.2, 84%, 4.9%;
    --card-foreground: 210, 40%, 98%;
    --popover: 222.2, 84%, 4.9%;
    --popover-foreground: 210, 40%, 98%;
    --primary: 263.4, 70%, 50.4%;
    --primary-foreground: 210, 40%, 98%;
    --secondary: 217.2, 32.6%, 17.5%;
    --secondary-foreground: 210, 40%, 98%;
    --muted: 217.2, 32.6%, 17.5%;
    --muted-foreground: 215, 20.2%, 65.1%;
    --accent: 217.2, 32.6%, 17.5%;
    --accent-foreground: 210, 40%, 98%;
    --destructive: 0, 62.8%, 30.6%;
    --destructive-foreground: 210, 40%, 98%;
    --border: 217.2, 32.6%, 17.5%;
    --input: 217.2, 32.6%, 17.5%;
    --ring: 263.4, 70%, 50.4%;
  }

  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground antialiased min-h-screen;
    font-feature-settings: "rlig" 1, "calt" 1;
  }

  /* Typography */
  h1 {
    @apply text-4xl font-extrabold tracking-tight lg:text-5xl;
  }

  h2 {
    @apply text-3xl font-bold tracking-tight lg:text-4xl;
  }

  h3 {
    @apply text-2xl font-bold tracking-tight lg:text-3xl;
  }

  h4 {
    @apply text-xl font-semibold tracking-tight lg:text-2xl;
  }
}

@layer components {
  /* Button styles */
  .button-hover-effect {
    @apply relative overflow-hidden transition-all duration-300;
  }

  .button-hover-effect::after {
    @apply absolute inset-0 bg-white/20 scale-x-0 transition-transform duration-300 origin-left content-[''];
  }

  .button-hover-effect:hover::after {
    @apply scale-x-100;
  }

  /* Card styles */
  .card-hover {
    @apply transition-all duration-300 hover:shadow-lg hover:-translate-y-1;
  }

  /* Loading animations */
  .animate-ripple {
    animation: ripple 0.6s linear;
  }

  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }

  /* Task priority indicators */
  .priority-dot {
    @apply w-2 h-2 rounded-full inline-block;
  }

  .priority-dot.high {
    @apply bg-destructive;
  }

  .priority-dot.medium {
    @apply bg-yellow-500;
  }

  .priority-dot.low {
    @apply bg-primary;
  }

  /* Progress bars */
  .progress-bar {
    @apply h-2 rounded-full bg-muted overflow-hidden;
  }

  .progress-bar-fill {
    @apply h-full bg-primary transition-all duration-300 ease-in-out;
  }

  /* Focus styles */
  .focus-ring {
    @apply focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2;
  }
  
  /* Custom scrollbar - requires tailwind-scrollbar plugin */
  .custom-scrollbar {
    @apply scrollbar-thin scrollbar-track-transparent scrollbar-thumb-muted/20 hover:scrollbar-thumb-muted/30;
  }
}